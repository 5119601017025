import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import MapBlade from "@organic-return/foundation-react/src/components/Blades/MapBlade"
import { getMapTheme } from "@organic-return/foundation-react/src/lib/util"


const LocationMap = ({ module }: any) => {

  const data = useStaticQuery(graphql`
      query {
        allAgilityLocationMap {
            nodes {
              contentID
              locations {
                customFields {
                  latitude
                  longitude
                  title
                }
              }
            }
          }
      }
    `)

  const location = data.allAgilityLocationMap?.nodes?.find((x: any) => x.contentID == module.contentID)?.locations?.map((l: any) => {
    return {
      location: {
        lat: l.customFields.latitude,
        lng: l.customFields.longitude
      }
    }
  })
  let theme: any = []
  if (module.customFields.mapTheme) {
    theme = getMapTheme(module.customFields.mapTheme)
  }

  return (

    <MapBlade
      mapWrapperProps={{ useSimpleMarker: true }}
      title={module.customFields.title}
      locations={location}
      mapProps={{
        mapContainerStyle: {
          width: "100%",
          height: "79vh"
        }, options: {
          styles: theme
        }
      }}>

    </MapBlade>

  )
}

export default LocationMap